import { SelectQuoteChildProps } from 'pages/partners/selectquote/ny';
import React, { FC } from 'react';

import { TrustedPartnerLarge } from './TrustedPartnerLarge';
import { TrustedPartnerSmall } from './TrustedPartnerSmall';

export interface TrustedPartnerProps extends SelectQuoteChildProps {}

export const TrustedPartner: FC<TrustedPartnerProps> = ({ ...childProps }) => (
  <>
    <TrustedPartnerLarge {...childProps} />
    <TrustedPartnerSmall {...childProps} />
  </>
);
