import { SelectQuoteChildProps } from 'pages/partners/selectquote/ny';
import React, { FC } from 'react';

import { MoreFreedom } from '../more-freedom';
import { MoreThanFinancialAdvice } from '../more-than-financial-advice';
import { OneStepCloser } from '../one-step-closer';
import { PeaceOfMind } from '../peace-of-mind';
import { StartsWithAPlan } from '../starts-with-a-plan';
import { TrustedPartner } from '../trusted-partner';

interface RetirableProcessProps extends SelectQuoteChildProps {}

export const RetirableProcess: FC<RetirableProcessProps> = ({
  ...childProps
}) => (
  <>
    <PeaceOfMind {...childProps} />
    <OneStepCloser />
    <StartsWithAPlan />
    <MoreThanFinancialAdvice />
    <MoreFreedom />
    <TrustedPartner {...childProps} />
  </>
);
