import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import { SelectQuoteChildProps } from 'pages/partners/selectquote/ny';
import React, { FC } from 'react';
import styled from 'styled-components';

import { body, heading, primaryCtaText, secondaryCtaText } from './content';

const ImageWrapper = styled(Flex)`
  padding-left: 20px;
`;

const ContentWrapper = styled(Flex)`
  padding: 0 20px;
`;

interface MobileHeroProps extends SelectQuoteChildProps {
  onLearnMoreClick: () => void;
}

export const MobileHero: FC<MobileHeroProps> = ({
  onConsultationClick,
  onLearnMoreClick,
}) => (
  <ShowOn screens={[Screen.Sm, Screen.Md, Screen.Lg]}>
    <Flex column alignItems="center">
      <ImageWrapper>
        <StaticImage src="../images/happy-couple.webp" alt="hero" />
      </ImageWrapper>
      <ContentWrapper column>
        <Heading variant={4} marginBottom={24} marginTop={32}>
          {heading}
        </Heading>
        <Body variant={1}>{body}</Body>
        <PrimaryButton
          buttonColor={ButtonColor.Peach}
          onClick={onConsultationClick}
          alignment="center"
          marginTop={24}
        >
          {primaryCtaText}
        </PrimaryButton>
        <PrimaryButton
          buttonColor={ButtonColor.Transparent}
          onClick={onLearnMoreClick}
          alignment="center"
          marginTop={24}
        >
          {secondaryCtaText}
        </PrimaryButton>
      </ContentWrapper>
    </Flex>
  </ShowOn>
);
