import { Grid } from 'common/layouts/grid/Grid';
import { GridColumn } from 'common/layouts/grid/GridColumn';
import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import { SelectQuoteChildProps } from 'pages/partners/selectquote/ny';
import React, { FC } from 'react';
import styled from 'styled-components';

import { body, heading, primaryCtaText, secondaryCtaText } from './content';

const Wrapper = styled(ShowOn).attrs({
  screens: [Screen.Xxl, Screen.Xl],
})`
  max-width: 1488px;
  margin: 0 auto;
  padding-left: 40px;
`;

interface DesktopHeroProps extends SelectQuoteChildProps {
  onLearnMoreClick: () => void;
}

export const DesktopHero: FC<DesktopHeroProps> = ({
  onConsultationClick,
  onLearnMoreClick,
}) => (
  <Wrapper>
    <Grid cols={8} gap={1} spacing={1}>
      <GridColumn span={5}>
        <Heading variant={2} marginBottom={24} marginTop={32}>
          {heading}
        </Heading>
        <Body variant={1}>{body}</Body>
        <Flex marginTop={32}>
          <PrimaryButton
            buttonColor={ButtonColor.Peach}
            onClick={onConsultationClick}
            alignment="center"
            marginRight={32}
          >
            {primaryCtaText}
          </PrimaryButton>
          <PrimaryButton
            buttonColor={ButtonColor.Transparent}
            onClick={onLearnMoreClick}
            alignment="center"
          >
            {secondaryCtaText}
          </PrimaryButton>
        </Flex>
      </GridColumn>
      <GridColumn span={3}>
        <StaticImage src="../images/happy-couple.webp" alt="hero" />
      </GridColumn>
    </Grid>
  </Wrapper>
);
