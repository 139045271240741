import Layout from 'common/layouts/Layout';
import { TrustPilot } from 'common/trustpilot/TrustPilot';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import { ConsultationModal } from 'page-components/partnerships/deprecated/selectquote/consultation-form/ConsultationModal';
import { Heading } from 'page-components/partnerships/deprecated/selectquote/heading';
import { Hero } from 'page-components/partnerships/deprecated/selectquote/hero';
import { RetirableProcess } from 'page-components/partnerships/deprecated/selectquote/retirable-process';
import { Steps } from 'page-components/partnerships/deprecated/selectquote/steps';
import { promoterVerificationComplianceFooterFor } from 'page-components/partnerships/shared/content';
import { Footer, FooterTemplate } from 'page-components/shared/footer';
import React, { FC } from 'react';

export interface SelectQuoteProps {}

export interface SelectQuoteChildProps {
  onConsultationClick: () => void;
}

const SelectQuote: FC<SelectQuoteProps> = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleConsultationClick = () => {
    setIsModalOpen(true);
  };

  const handleConsultationClose = () => {
    setIsModalOpen(false);
  };

  const childProps = {
    onConsultationClick: handleConsultationClick,
  };

  return (
    <Layout>
      <Heading {...childProps} />
      <PageWrapper background={colors.greenLight}>
        {isModalOpen && <ConsultationModal onClose={handleConsultationClose} />}
        <Hero {...childProps} />
        <RetirableProcess {...childProps} />
        <TrustPilot
          template="fullSizeCarousel"
          heading="We've helped more than 50,000 people just like you."
        />
        <Steps {...childProps} />
        <Footer
          template={FooterTemplate.Full}
          additionalFootnotes={[
            promoterVerificationComplianceFooterFor('SelectQuote')
          ]}
          {...childProps}
        />
      </PageWrapper>
    </Layout>
  );
};

export default SelectQuote;

export const Head = () => (
  <SEO
    title="SelectQuote + Retirable"
    description="Learn more about how SelectQuote and Retirable are working together to help you have peace of mind in retirement."
  />
);
