import { SelectQuoteChildProps } from 'pages/partners/selectquote/ny';
import React, { FC } from 'react';

import { StepsLarge } from './StepsLarge';
import { StepsSmall } from './StepsSmall';

interface StepsProps extends SelectQuoteChildProps {}

export const Steps: FC<StepsProps> = (props) => (
  <>
    <StepsSmall {...props} />
    <StepsLarge {...props} />
  </>
);
