import { SelectQuoteChildProps } from 'pages/partners/selectquote/ny';
import React, { FC } from 'react';

import { DesktopHero } from './DesktopHero';
import { MobileHero } from './MobileHero';

interface HeroProps extends SelectQuoteChildProps {}

const handleLearnMoreClick = () => {
  const peaceOfMindSection = document.getElementById('peace-of-mind');
  if (peaceOfMindSection) {
    window.scrollTo({
      top: peaceOfMindSection.offsetTop - 24,
      behavior: 'smooth',
    });
  }
};

export const Hero: FC<HeroProps> = (props) => (
  <>
    <DesktopHero {...props} onLearnMoreClick={handleLearnMoreClick} />
    <MobileHero {...props} onLearnMoreClick={handleLearnMoreClick} />
  </>
);
