import { Grid } from 'common/layouts/grid/Grid';
import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { SelectQuoteChildProps } from 'pages/partners/selectquote/ny';
import React, { FC } from 'react';
import styled from 'styled-components';

import { body, heading } from './content';
import { Step } from './Step';

const StepsWrapper = styled(Flex)`
  max-width: 1488px;
  margin: 40px auto;
  padding: 120px 200px;
  background-color: ${colors.forestGreen};
  border-radius: 32px;
`;

interface StepsLargeProps extends SelectQuoteChildProps {}

export const StepsLarge: FC<StepsLargeProps> = ({ onConsultationClick }) => (
  <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <StepsWrapper column>
      <ResponsiveHeading color="white" variant={2} marginBottom={32}>
        {heading}
      </ResponsiveHeading>
      <ResponsiveBody color="white" variant={1} marginBottom={80}>
        {body}
      </ResponsiveBody>
      <Grid cols={2} gap={6}>
        <Step stepNumber={1} />
        <Step stepNumber={2} />
        <Step stepNumber={3} />
        <Step stepNumber={4} />
      </Grid>
      <Flex justifyContent="center">
        <PrimaryButton
          buttonColor={ButtonColor.Peach}
          onClick={onConsultationClick}
          alignment="center"
          marginTop={48}
        >
          Get your FREE consultation & Retirement Plan
        </PrimaryButton>
      </Flex>
    </StepsWrapper>
  </ShowOn>
);
